import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/NavBar';
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
    this.className = props.className;
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
        <Container fluid className={this.props.className}>
            <Row>
                <Col xs={{ span: 10, offset: 1 }}> {/* lg={{ span: 8, offset: 2 }}> */}
                    
                    <Navbar expand="md" collapseOnSelect>
                        <Nav>
                            <Nav.Link as={Link} to='/' href><Navbar.Brand><span className='brand-text'>Marmalade Skies</span></Navbar.Brand></Nav.Link>
                        </Nav>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="ml-auto">

                                <Nav.Link as={NavLink} exact to='/' href>Home</Nav.Link>
{/*
                            <Nav.Link as={NavLink} to='/menus' href>Menus</Nav.Link>
*/}
                                <Nav.Link as={NavLink} to='/gallery' href>Gallery</Nav.Link>
                                <Nav.Link as={NavLink} to='/testimonials' href>Testimonials</Nav.Link>
                                <Nav.Link as={NavLink} to='/contacts' href>Contacts</Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
            </Row>
        </Container>
    );
  }
}
