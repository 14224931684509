
let apiRoot = `${window.location.origin}/api`;

const getParagraphUri = (key) => `${apiRoot}/paragraph/${key}`;
const putParagraphUri = getParagraphUri;

const getPhotoUri = (key) => `${apiRoot}/photo/${key}`;
const putPhotoUri = getPhotoUri;
const postPhotoUri = `${apiRoot}/photo`;

const getPhotoListUri = (key) => `${apiRoot}/photolist/${key}`;
const putPhotoListUri = getPhotoListUri;

export async function getParagraphAsync(key) {
    var response;
    try {
        response = await fetch(getParagraphUri(key));

    } catch (e) {
        return {key, text: ''};
    }

    try {
        return response.json();
    } catch (e2) {
        return {key, text: ''};
    }
}

export async function updateParagraphAsync(paragraph) {
    const init = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(paragraph),
    };

    const response = await fetch(putParagraphUri(paragraph.key), init);
    return response.json();
}

export async function getPhotoAsync(key) {
    var response;
    try {
        response = await fetch(getPhotoUri(key));

    } catch (e) {
        return { key, uri: '', alt: '' };
    }

    try {
        return response.json();
    } catch (e2) {
        return { key, uri: '', alt: '' };
    }
}

export async function updatePhotoAsync(photo) {
    const init = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(photo),
    };

    const response = await fetch(putPhotoUri(photo.key), init);
    return response.json();
}

export async function getPhotoListAsync(key) {
    var response;
    try {
        response = await fetch(getPhotoListUri(key));
        if (response.status !== 200) {
            let exception = {error: response.text}
            throw exception
        }

    } catch (e) {
        return { key, photos: [] };
    }

    try {
        return response.json();
    } catch (e2) {
        return { key, photos:[] };
    }
}

export async function updatePhotoListAsync(photoList) {
    const init = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(photoList),
    };

    const response = await fetch(putPhotoListUri(photoList.key), init);
    return response.json();
}

export async function uploadMultipartPhotoAsync(form) {
    const init = {
        method: 'POST',
        body: form
    }

    const response = await fetch(postPhotoUri, init)
    return response.json()
}

export async function updatePhotoListWithFilesAsync(key, form) {
    const init = {
        method: 'PUT',
        body: form
    }

    const response = await fetch(putPhotoListUri(key), init)
    return response.json()
}
