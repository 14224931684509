import React from 'react';
import Container from 'react-bootstrap/Container';
import { NavMenu } from './NavMenu';
import BannerImage from './BannerImage';
import './Layout.css';

export const Layout = (props) => {
  return (
      <>
        <header id='top-nav'>
          <NavMenu className='nav-menu'/>
        </header>
        <BannerImage />
        <main id='main-content-frame'>
           <Container>
            {props.children}
          </Container>
        </main>
      </>
    );
}

export default Layout;
