import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function TitleBlock(props) {
    const { xs, sm, md, lg, xl, title } = props
    return (
        <>
            <Row className='title-block'>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                    <h1 className='page-title'>{title}</h1>
                </Col>
            </Row>
        </>
    );
}

export default TitleBlock;
