import Cookies from 'js-cookie';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TitleBlock from './TitleBlock'
import Paragraph from './Paragraph';
import * as api from '../api';


let isAdmin = (Cookies.get('admin_mode') === "true");
let canEdit = isAdmin;

async function getParagraph(key) {
  try {
    return api.getParagraphAsync(key)
  } catch {
    return {text: ''};
  }
}

async function saveParagraph(text, key) {
    const paragraph = { key, text }

    return api.updateParagraphAsync(paragraph);
}

function Contacts() {
    return (
        <>
            <TitleBlock title='Contacts' xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} />
            <Row>
                <Col xs={{span: 10, offset: 1}} lg={{span: 8, offset: 2}}>
                    <Paragraph getParagraph={getParagraph} saveParagraph={saveParagraph} paragraphKey="contacts:main" canEdit={canEdit} />
                </Col>
            </Row>
        </>
    );
}

export default Contacts;
