import Cookies from 'js-cookie';
import * as api from './api';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Paragraph from './components/Paragraph';
import { Layout } from './components/Layout';
import ScrollToTop from './components/ScrollToTop'
import Home from './components/Home';
//import Menus from './components/Menus';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import Contacts from './components/Contacts';

import './custom.css'

function App() {

    return (
        <Layout>
            <ScrollToTop>
            <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
{/*
                <Route exact path='/menus'>
                    <Menus />
                </Route>
                <Route path='/menus/brunch'>
                    <Brunch />
                </Route>
                <Route path='/menus/canapés'>
                    <Canapes />
                </Route>
                <Route path='/menus/sandwiches'>
                    <Sandwiches />
                </Route>
                <Route path='/menus/kids-parties'>
                    <KidsParties />
                </Route>
*/}
            <Route path='/gallery'>
              <Gallery />
            </Route>
            <Route path='/testimonials'>
              <Testimonials />
            </Route>
            <Route path='/contacts'>
              <Contacts />
            </Route>
            <Route path='*'>
              <Redirect to='/' />
            </Route>
            </Switch>
            </ScrollToTop>
        </Layout>
    );
}

let isAdmin = (Cookies.get('admin_mode') === "true");
let canEdit = isAdmin;

async function getParagraph(key) {
    try {
        return api.getParagraphAsync(key)
    } catch {
        return { text: '' };
    }
}

async function saveParagraph(text, key) {
    const paragraph = { key, text }

    return api.updateParagraphAsync(paragraph);
}

function Brunch() {
    return (
        <Row>
            <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h1 className='page-title'>Brunch</h1>
                <Paragraph getParagraph={getParagraph} saveParagraph={saveParagraph} paragraphKey="brunch:main" canEdit={canEdit} />
            </Col>
        </Row>
    );
}
function Canapes() {
    return (
        <Row>
            <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h1 className='page-title'>Canapés</h1>
                <Paragraph getParagraph={getParagraph} saveParagraph={saveParagraph} paragraphKey="canapés:main" canEdit={canEdit} />
            </Col>
        </Row>
    );
}

function Sandwiches() {
    return (
        <Row>
            <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h1 className='page-title'>Sandwich Platters</h1>
                <Paragraph getParagraph={getParagraph} saveParagraph={saveParagraph} paragraphKey="sandwiches:main" canEdit={canEdit} />
            </Col>
        </Row>
    );
}

function KidsParties() {
    return (
        <Row>
            <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h1 className='page-title'>Kids' Parties</h1>
                <Paragraph getParagraph={getParagraph} saveParagraph={saveParagraph} paragraphKey="kids-parties:main" canEdit={canEdit} />
            </Col>
        </Row>
    );
}


export default App;

