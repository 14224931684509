import React from 'react';
import './BannerImage.css';

function BannerImage(props) {
    var className = 'banner-image'
    if (props.className) {
        className = className + ' ' + props.className;
    }

    return (
        <>
            <div className={className} />
        </>
    );
}

export default BannerImage;
