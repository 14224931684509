import Cookies from 'js-cookie';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TitleBlock from './TitleBlock'
import Paragraph from './Paragraph';
import * as api from '../api';


let isAdmin = (Cookies.get('admin_mode') === "true");
let canEdit = isAdmin;

async function getParagraph(key) {
  try {
    return api.getParagraphAsync(key)
  } catch {
    return {text: ''};
  }
}

async function saveParagraph(text, key) {
    const paragraph = { key, text }

    return api.updateParagraphAsync(paragraph);
}

function Home() {
    return (
        <>
            <TitleBlock title='Marmalade Skies Catering' xs={{span: 10, offset: 1}} lg={{span: 8, offset: 2}} />

            <Paragraph getParagraph={getParagraph} saveParagraph={saveParagraph} paragraphKey="home:main" canEdit={canEdit} />

            <Row>
                <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                    <p>Please contact <a href="mailto:heather@mskies.co.uk">Heather</a> for a no obligation quote.</p>
                </Col>
            </Row>
        </>
    );
}

export default Home;
