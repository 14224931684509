import Cookies from 'js-cookie'
import * as api from '../api'
import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import CardColumns from 'react-bootstrap/CardColumns'
import Form from 'react-bootstrap/Form'
import TitleBlock from './TitleBlock'

let isAdmin = (Cookies.get('admin_mode') === "true")
let canEdit = isAdmin

const getPhotos = (key) => api.getPhotoListAsync(key)

const image = ({ key, uri, alt }) => ({ key, src: uri, alt })

const imageCard = ({key, src, alt}) => (
    <Card key={key}>
        <Card.Img src={src} alt={alt} />
    </Card>
)

const EditImageCard = ({ photo, imagekey, src, alt, updatePhoto, deletePhoto }) => {
    let [editingImage, setEditingImage] = useState(false)

    let key = imagekey

    const editingImageCardContent = () => {

        const updateImage = (event) => {
            event.preventDefault()
            event.stopPropagation()

            let form = event.target
            let fields = form.children
            let alt = fields.photoAlt.value

            let photo = {
                key,
                alt
            }

            if (fields.newFile.files.length > 0) {
                updatePhoto(key, photo, fields.newFile.files[0])
            } else {
                updatePhoto(key, photo)
            }

            setEditingImage(false)
        }

        const cancelEditingImage = (event) => {
            event.preventDefault()
            event.stopPropagation()

            setEditingImage(false)
        }

        return (
            <Form onSubmit={updateImage}>
                <Form.Control name="newFile" type="file" />
                <Form.Control name="photoAlt" size="sm" type="text" defaultValue={photo.alt} placeholder="Photo alt text" />
                <Button type="submit">Save</Button><Button onClick={cancelEditingImage} >Cancel</Button>
            </Form>
        )
    }

    const readyToEditImageCardContent = () => {

        const startEditingImage = (event) => {
            event.preventDefault()
            event.stopPropagation()

            setEditingImage(true)
        }

        const deleteImage = (event) => {
            event.preventDefault()
            event.stopPropagation()

            deletePhoto(key)
        }

        return (
            <>
                <Card.Img src={src} alt={alt} />
                <Card.Footer>
                    <Button onClick={startEditingImage}>Change</Button><Button onClick={deleteImage}>Delete</Button>
                </Card.Footer>
            </>
        )
    }

    return (
        <Card key={key} >
            {editingImage ? editingImageCardContent() : readyToEditImageCardContent()}
        </Card>
    )
}

const randomPhotoKey = () => {
    let key = ''

    let characters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    for (var i = 0; i < 8; i++) {
        let randomIndex = Math.floor(Math.random() * characters.length)
        key = key + characters[randomIndex]
    }

    return key
}

const InsertImageCard = ({ addPhoto }) => {
    let [insertingImage, setInsertingImage] = useState(false)

    const insertingImageCardContent = () => {

        const insertImage = (event) => {
            event.preventDefault()
            event.stopPropagation()

            let form = event.target
            let fields = form.children

            if (fields.newFile.files.length === 0) {
                return
            }

            let alt = fields.photoAlt.value
            let key = randomPhotoKey()

            let photo = {
                key,
                alt
            }

            addPhoto(photo, fields.newFile.files[0])
            setInsertingImage(false)
        }

        const cancelInsertingImage = (event) => {
            event.preventDefault()
            event.stopPropagation()

            setInsertingImage(false)
        }

        return (
            <Form onSubmit={insertImage}>
                <Form.Control name="newFile" type="file" />
                <Form.Control name="photoAlt" size="sm" type="text" defaultValue="" placeholder="Photo alt text" />
                <Button type="submit">Insert</Button><Button onClick={cancelInsertingImage} >Cancel</Button>
            </Form>
        )
    }

    const readyToInsertImageCardContent = () => {

        const startInsertingImage = (event) => {
            event.preventDefault()
            event.stopPropagation()

            setInsertingImage(true)
        }

        return (
            <>
                <Card.Footer>
                    <Button onClick={startInsertingImage}>[insert image]</Button>
                </Card.Footer>
            </>
        )
    }

    return (
        <Card>
            {insertingImage ? insertingImageCardContent() : readyToInsertImageCardContent()}
        </Card>
    )
}

function Gallery() {

    const [galleryState, setGalleryState] = useState({ photos: [], loading: true, editing: false });

    if (galleryState.loading) {
        getPhotos('gallery')
            .then(
                ({ photos }) => setGalleryState( {...galleryState, photos, loading: false} )
            )

        return (<div>Loading...</div>)
    }

    const updatePhotos = (photos, filemap) => {
        let form = new FormData()
        form.append('photolist', JSON.stringify( { key: 'gallery', photos } ))
        for (let [key, file] of filemap) {
            form.append(key, file, key)
        }
        return api.updatePhotoListWithFilesAsync('gallery', form)
    }

    const updatePhoto = (oldKey, photo, file) => {
        let newPhotos = []
        for (let galleryPhoto of galleryState.photos) {
            if (galleryPhoto.key === oldKey) {
                newPhotos.push(photo)
            } else {
                newPhotos.push(galleryPhoto)
            }
        }

        let filemap = new Map()
        if (file) {
            filemap.set(photo.key, file)
        }

        updatePhotos(newPhotos, filemap)
        setGalleryState({ ...galleryState, photos: newPhotos })
    }

    const addPhoto = (position, photo, file) => {
        let foundPosition = false
        let newPhotos = []
        let i = 0;
        for (let galleryPhoto of galleryState.photos) {
            if (i === position) {
                foundPosition = true
                newPhotos.push(photo)
            }

            newPhotos.push(galleryPhoto)
            i++
        }

        if (!foundPosition) {
            newPhotos.push(photo)
        }

        let filemap = new Map()
        filemap.set(photo.key, file)

        updatePhotos(newPhotos, filemap)
        setGalleryState({ ...galleryState, photos: newPhotos })
    }

    const addPhotoAtPosition = (position) => (photo, file) => addPhoto(position, photo, file)
    
    const deletePhoto = (key) => {
        let newPhotos = []
        let foundPhotoToDelete = false
        for (let galleryPhoto of galleryState.photos) {
            if (galleryPhoto.key !== key) {
                newPhotos.push(galleryPhoto)
            } else {
                foundPhotoToDelete = true
            }
        }

        if (foundPhotoToDelete) {
            updatePhotos(newPhotos, new Map())
            setGalleryState({...galleryState, photos: newPhotos})
        }
    }

    const startEditing = (event) => {
        event.preventDefault()
        event.stopPropagation()

        setGalleryState({ ...galleryState, editing: true })
    }

    const stopEditing = (event) => {
        event.preventDefault()
        event.stopPropagation()

        setGalleryState({ ...galleryState, editing: false })
    }

    const editButton = () => <Button onClick={startEditing}>Edit</Button>

    const renderViewGallery = () =>
        <>
            <CardColumns>
                {galleryState.photos.map(p => imageCard(image(p)))}
            </CardColumns>
            { canEdit ? editButton() : null }
        </>

    const renderEditGallery = () =>
        <>
            <CardColumns>
                {galleryState.photos.map( (p, ix) =>
                    <>
                        <InsertImageCard key={'B4' + p.key} addPhoto={addPhotoAtPosition(ix)} />
                        <EditImageCard
                            key={p.key}
                            photo={p}
                            imagekey={p.key}
                            src={p.uri}
                            alt={p.alt}
                            updatePhoto={updatePhoto}
                            deletePhoto={deletePhoto} />
                    </>
                )}
                <InsertImageCard key='END' addPhoto={addPhotoAtPosition(galleryState.photos.length)} />
            </CardColumns>
            <Button onClick={stopEditing}>Cancel</Button>
        </>

    return (
        <>
            <TitleBlock title='Gallery' xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} />
            <Row>
                <Col xs={{span: 10, offset: 1}} lg={{span: 8, offset: 2}}>
                    {galleryState.editing ? renderEditGallery() : renderViewGallery()}
                </Col>
            </Row>
        </>
    )
}

export default Gallery;