import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import RichTextEditor, { createValueFromString, createEmptyValue } from 'react-rte'
import './ParagraphForm.css'

export default function ParagraphForm({ text, onSave, onCancel }) {

    const initialState = text ? createValueFromString(text, 'markdown') : createEmptyValue()
    const [editorState, setEditorState] = useState(initialState)

    const handleChange = (value) => {
        setEditorState(value)
    };

    const handleSubmit = () => {
        let markdown = editorState.toString('markdown')
        onSave(markdown)
    };

    /* EXAMPLE FROM https://github.com/sstur/react-rte
    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };
    */

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD' },
            { label: 'Italic', style: 'ITALIC' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' }
        ]
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <Form.Label><span className="italic">editing paragraph...</span></Form.Label>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                    <RichTextEditor value={editorState} toolbarConfig={toolbarConfig} onChange={handleChange} />
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                    <Button type="submit">Save</Button><Button onClick={onCancel}>Cancel</Button>
                </Col>
            </Row>
        </Form>
    );
}
