import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ReactMarkdown from 'react-markdown'
import ParagraphForm from './ParagraphForm'
import './Paragraph.css'

function Paragraph({getParagraph, saveParagraph, paragraphKey, canEdit}) {

    const [paragraphState, setParagraphState] = useState({text: '', loading: true, editing: false});

    if (paragraphState.loading) {
        getParagraph(paragraphKey)
            .then(
                data => setParagraphState( { ...paragraphState, text: data.text, loading: false} )
            )

        return (<div>Loading...</div>);

    } else {

        const save = newParagraph => 
            saveParagraph(newParagraph, paragraphKey)
                .then( setParagraphState( { text: newParagraph, loading: false, editing: false} ) )
        
        const startEditing = () => { setParagraphState({ ...paragraphState, editing: true }) }
        const cancelEditing = () => { setParagraphState({...paragraphState, editing: false})}

        const renderEditParagraph = (
            <ParagraphForm text={paragraphState.text} onSave={save} onCancel={cancelEditing}/>
        );

        const editButton = (
            <Row>
                <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                    <Button onClick={startEditing}>Edit</Button>
                </Col>
            </Row>
        );

        const renderViewParagraph = (
            <>
               <Row>
                    <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                        <ReactMarkdown>{paragraphState.text}</ReactMarkdown>
                    </Col>
                </Row>
                {canEdit ? editButton : null}
            </>
        );

        return (
            <>
                {paragraphState.editing ? renderEditParagraph : renderViewParagraph }
            </>
        );
    }
}

export default Paragraph;