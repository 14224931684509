import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardColumns from 'react-bootstrap/CardColumns';
import TitleBlock from './TitleBlock'

const testimonials = [
{
    msg: 'Heather - thank you so much for providing the vegetarian canapés and sandwiches for my 50th bash. Everything was fantastically presented and tasted delicious. I would definitely recommend you.',
    author: 'Caroline Whitehurst',
    event: '',
    org: 'Cramond',
    loc: ''
},
{
    msg: 'Everyone loved the food, apparently there was a real buzz about lunch',
    author: 'Nadia',
    event: '',
    org: 'Scottish Wildlife Trust',
    loc: ''
},
{
    msg: 'Dear Heather, just to say a big thank you for the super food you made for Sunday and for delivering and setting everything out. We have had lots of thank yous and they all say it was the best party yet!! With good wishes, Iona and Brian Hughes.',
    author: '',
    event: 'Summer garden party',
    org: '',
    loc: 'Edinburgh'
},
{
    msg: 'The food was great and your manner is lovely and friendly.',
    author: 'C McKerracher',
    event: '',
    org: '',
    loc: ''
},
{
    msg: 'Heather, the food was brilliant! we were all stuffed to the gills! You did a fantastic job in our very primitive conditions. Thank you so much.',
    author: 'J Cameron',
    event: 'Razmatazz volunteers dinner',
    org: 'Redhall walled Garden',
    loc: 'Edinburgh'
},
{
    msg: 'Great buffet, nothing but compiliments from all and it made it so much easier for us to enjoy ourselves, which we really did.',
    author: 'S Robertson',
    event: 'Perthshire garden party',
    org: '',
    loc: ''
},
{
    msg: 'Thanks again, so much for Friday, it was a truly fabulous lunch, I have already had an email from Gavin at BAA commenting on how good the soup was!',
    author: 'K McIntosh',
    event: 'buffet lunch at Drum Castle',
    org: 'National Trust for Scotland',
    loc: ''
},
{
    msg: 'You were fab! Thanks so much Heather and to Ryan. I was more than happy with the buffet',
    author: 'J Bond',
    event: 'Volunteer\'s Christmas Ceilidh',
    org: 'National Trust for Scotland',
    loc: ''
},
{
    msg: 'A huge THANK YOU to you and Ryan for the wonderful dinner and lunches at Mar Lodge. Thanks so much for all your hard work preparing for the event...everyone was completely enthralled with the tastes and range of dishes, all beautifully presented',
    author: 'J Duncan',
    event: '',
    org: 'BTCV',
    loc: ''
},
{
    msg: 'The catering on Saturday was well appreciated. Everyone remarked on the high quality and tastiness of the food. Thank you again for all your efforts.',
    author: 'J Stewart',
    event: 'canapés and buffet',
    org: '',
    loc: 'Dunblane'
},
{
    msg: 'Thanks Heather, the breakfast was fantastic and we have had lots of positive feedback from the guests',
    author: 'P Brown',
    event: 'charity breakfast',
    org: 'St Thomas\' Church',
    loc: 'Edinburgh'
}
];


const testimonialCard = ({ msg, author, org }, index) => (
    <Card key={index}>
        <Card.Body>
            <Card.Text>"{msg}"</Card.Text>
            <Card.Text>{author}{org ? `  (${org})` : ''}</Card.Text>
        </Card.Body>
    </Card>
);

function Testimonials() {
    return (
        <>
            <TitleBlock title='Testimonials' xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} />
            <Row>
                <Col xs={{span: 10, offset: 1}} lg={{span: 8, offset: 2}}>
                    <CardColumns>
                    {testimonials.map(testimonialCard)}
                    </CardColumns>
                </Col>
            </Row>
        </>
    );
}

export default Testimonials;